<template>
  <div
    ref="elementRef"
    class="relative grid"
    @click="handlePromotionClick"
  >
    <div
      v-style:h="{ sm: `calc(100svh - ${pxToRem(header.height[header.transparent ? 'promoBar' : 'full'])})` }"
      class="relative grid-area-stack"
    >
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        class="full"
        fit="cover"
        :lazy
        :position="{ sm: 'top', md: 'center' }"
        :props-img="{ class: 'h-full' }"
      />
      <vf-video
        v-if="responsiveMedia.video"
        v-bind="responsiveMedia.video"
        ref="videoRef"
        :autoplay="!lazy"
        class="full"
        fit="cover"
        loop
        muted
      />
    </div>
    <div
      v-style:p="{ sm: '7.5rem 1rem 6.5rem', lg: '7.5rem 2.5rem 2.5rem' }"
      class="relative mt-a w-full grid-area-stack <lg:text-center"
      style="background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))"
    >
      <h1 v-if="title" class="elevate-title-2 c-white">
        {{ title }}
      </h1>
      <p v-if="subtitle" class="elevate-body-3 lg:elevate-body-2 mt-4 c-white">
        {{ subtitle }}
      </p>
      <div
        v-if="linkType !== 'No-CTA' && targets.length"
        class="gap-4 i-flex not-first:mt-6"
      >
        <div
          v-style="equalTargetStyle"
          class="gap-4"
          :class="isEqualWidthButtons ? 'grid' : 'flex wrap'"
        >
          <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  SectionContextKey,
  type SectionProvider
} from '#content/components/cms/section/context'
import type { HeroContent } from '#types/components/cms/hero'

const { content } = defineProps<{
  content: HeroContent
}>()

const {
  equalTargetSize,
  linkType,
  media,
  promotionTracking,
  subtitle,
  targets = [],
  title
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia } = useCms()
const header = useHeaderStore()
const { elementRef, handlePromotionClick } = usePromotionTracking(promotionTracking, content.name)

const { lazy } = inject(SectionContextKey, {} as SectionProvider)
const responsiveMedia = getMedia(media)
const videoRef = ref()

const isEqualWidthButtons = getValueForBreakpoint('sm', equalTargetSize)

const equalTargetStyle = {
  ...(isEqualWidthButtons && {
    'grid-cols': {
      sm: '1fr',
      md: `repeat(${targets.length}, minmax(0,1fr))`
    }
  })
}

onMounted(() => videoRef.value?.play().catch((e) => log.error(e)))
</script>
